import React from "react"
import PropTypes from "prop-types"
import ContentfulComponents from "../helpers/ContentfulComponents"
import Layout from "../components/Layout/Layout"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const Page = ({ pageContext }) => {
    const { globalComponents, componentProps, hero, hideScrollToTopButton, seoData, pageType, dataLayer } = pageContext

    // GTM custom dataLayer Object based on environment
    if (typeof window !== "undefined") {
        const dataLayerObj = {
            ...dataLayer,
            page: {
                url: window.location.href,
                title: seoData?.title || seoData?.metaTitle,
            },
            content: {
                type: pageType || "others",
                category: "",
            },
        }
        window.gtmDataLayer = dataLayerObj
    }
    return (
        <Layout
            seoData={seoData}
            globalComponents={globalComponents}
            hero={hero}
            hideScrollToTopButton={hideScrollToTopButton}
        >
            <ContentfulComponents componentsProps={componentProps} pageType={pageType} />
        </Layout>
    )
}
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            {process.env.GATSBY_ACTIVE_ENV === 'production' && <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{__html: gTagScriptConsent}}
            />}
        </>
    )
}

Page.propTypes = {
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        hideScrollToTopButton: PropTypes.bool,
        hero: PropTypes.object,
        seoData: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            ogTitle: PropTypes.string,
            ogDescription: PropTypes.string,
            ogType: PropTypes.string,
            twitterTitle: PropTypes.string,
            twitterDescription: PropTypes.string,
            canonicalLink: PropTypes.string,
            metaTitle: PropTypes.string,
            featuredImage: PropTypes.shape({
                url: PropTypes.string,
            }),
        }),
    }),
}

export default Page
